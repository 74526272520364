<template>
  <main>    
    <section id="inicio">
      <div class="mapa-home">
        <button class="button-home" @click="$router.push('/mapa')">
          Acesse o mapa!
        </button>        
      </div>
      <h1 class="mapa-title">A Baía de Guanabara é parte do patrimônio natural do Rio de Janeiro e de todo o Brasil. Seu valor ambiental, cultural e econômico é incalculável.</h1>
    </section>
    <v-container class="mega-container" fluid>
      <h1 v-if="!isBig" class="section-txt" style="padding-bottom: 0px !important;">A Baía de Guanabara é parte do patrimônio natural do Rio de Janeiro e de todo o Brasil. Seu valor ambiental, cultural e econômico é incalculável.</h1>
      <h1 class="section-txt" style="padding-bottom: 0px !important;">O <b> Projeto De Olho na Guanabara</b> nasceu para contribuir com a fiscalização ambiental feita pelas comunidades de pescadores artesanais da região.</h1>
      <h1 class="section-txt">O aplicativo faz o mapeamento de onde estão os pescadores artesanais da Baía de Guanabara e onde estão ocorrendo violações <b>socioambientais</b> feitas, principalmente, pela indústria de petróleo e gás na região.</h1>
      <div v-if="!isBig" style="position: relative;">
        <v-img @click="scrollToSection('impacto')" class="rotate-peixe" src="@/assets/peixe_azul_rotate.png" :height="!isBig ? '45px': '80px'" width="auto" contain></v-img>
      </div>
      <div v-if="isBig" style="position: relative;">
        <v-img @click="scrollToSection('impacto')" class="rotate-peixe mt-4 mb-4" src="@/assets/peixe_azul_rotate.png" :height="!isBig ? '45px': '80px'" width="auto" contain></v-img>
      </div>
      <section id="impacto" class="mb-6">
        <h1 class="section-txt">Este aplicativo ajuda a proteger o território de mais de:</h1>
        <div class="cards-wrapper">
          <v-img v-for="card in infoCards" :key="card" contain  :src="getCardImg(card)"></v-img>
        </div>
      </section>
      <div v-if="!isBig" style="position: relative;">
        <v-img @click="scrollToSection('sobre')" class="rotate-peixe" src="@/assets/peixe_azul_rotate.png" :height="!isBig ? '45px': '80px'" width="auto" contain></v-img>
      </div>
      <div v-else style="position: relative;">
        <v-img @click="scrollToSection('sobre')" class="rotate-peixe mt-14 mb-6" src="@/assets/peixe_azul_rotate.png" :height="!isBig ? '45px': '80px'" width="auto" contain></v-img>
      </div>
      <section id="sobre">
        <h1 class="section-txt" style="padding-bottom: 0px !important;">O Projeto De Olho na Guanabara nasceu para contribuir com a fiscalização ambiental feita pelas comunidades de pescadores artesanais da região. </h1>
        <h1 class="section-txt" style="padding-bottom: 0px !important;">Os pescadores e pescadoras artesanais da região, que sempre contribuíram para fiscalizar irregularidades ambientais na região, agora, possuem uma ferramenta para denunciar as irregularidades socioambientais, principalmente as cometidas pelas indústrias de petróleo e gás.</h1>
        <!-- pensei em colocar o carrosel de depoimentos qui -->
        <h1 class="section-txt" style="padding-bottom: 0px !important;">Os pescadores credenciados na Rede Ahomar poderão utilizar o aplicativo De Olho na Baía de Guanabara para iniciar o processo de denúncia, com foto e localização exata onde visualizou a ocorrência. A denúncia, após verificação da coordenadoria da Rede Ahomar,  ficará registrada no mapa e em um passo seguinte, será encaminhada para os órgãos de fiscalização oficiais do Governo Brasileiro (IBAMA, ICMbio, Marinha, entre outros).</h1>
        <v-img  class="my-4" src="@/assets/plataforma.png" :height="isBig ? '600px' : ''" width="auto" contain></v-img>
      </section>
      <div style="position: relative;">
        <v-img @click="scrollToSection('equipes')" class="rotate-peixe mt-4 mb-2" src="@/assets/peixe_azul_rotate.png" :height="!isBig ? '45px': '80px'" width="auto" contain></v-img>
      </div>
      <section id="equipes">
        <h1 class="section-txt" style="padding-bottom: 0px !important;">Os pontos relacionados à estrutura dos combustíveis fósseis, Unidades de Consevação e Sindicatos, são de conhecimento público, retirados de sites oficiais do governo. </h1>
        <h1 class="section-txt" style="padding-bottom: 0px !important;">As denúncias são feitas por pescadores devidamente credenciados da Rede Ahomar e cada ponto publicado foi verificado antes de se tornar público, dessa forma, garantimos a veracidade das informações publicadas nesse site.</h1>
        <v-img  class="my-4" src="@/assets/ahomar_home.jpg" :height="isBig ? '600px' : ''" width="auto" contain></v-img>
        <h1 class="section-txt" style="padding-bottom: 0px !important;"><b>IMPORTANTE: Os dados dos denunciantes são protegidos, não publicamos nem compartilhamos o nome ou qualquer outra informação dos registrados neste aplicativo que tem por finalidade a proteção e preservação da Baía de Guanabara.</b></h1>

    </section>
    <div style="position: relative;">
      <v-img @click="scrollToSection('realizacao')" class="rotate-peixe mt-8 mb-4" src="@/assets/peixe_azul_rotate.png" :height="!isBig ? '45px': '80px'" width="auto" contain></v-img>
    </div>
  </v-container>
    <section id="realizacao" class="my-4">
      <h1 class="section-txt">O Projeto De Olho na Guanabara foi realizado pela Associação Homens e Mulheres do Mar (AHOMAR), em parceria com a 350.org Brasil.</h1>    
      <v-carousel class="carousel" cycle :height="isBig? '620px':'570px'" width="300" hide-delimiters hide-delimiter-background>
        <v-carousel-item>
          <div class="carousel-wrapper">
            <h2 class="carousel-title">AHOMAR</h2>
            <span class="carousel-text">A Associação Homens do Mar da Baía de Guanabara (AHOMAR) é uma organização de âmbito nacional que representa pescadores e comunidades pesqueiras da região da Baía de Guanabara, no estado do Rio de Janeiro e em todo o país.</span> 
            <span class="carousel-text">Ela tem como objetivo principal defender os direitos e interesses dos pescadores artesanais, bem como promover a preservação ambiental e o desenvolvimento sustentável das atividades pesqueiras na região.</span>
            <v-img class="rotate-peixe mt-14 mb-8" src="@/assets/peixe.png" :height="!isBig ? '30px': '40px'" width="auto" contain></v-img>
          </div>
        </v-carousel-item>
        <v-carousel-item>
          <div class="carousel-wrapper">
            <h2 class="carousel-title">350.org Brasil</h2>
            <span class="carousel-text">A 350.org Brasil é uma organização global que luta contra as mudanças climáticas causadas, principalmente pelo avanço da indústria de petróleo e gás, e promove campanhas e ações que visam a segurança climática e um futuro melhor construído com o poder de pessoas comuns, impulsionado por energia renovável e baseado na justiça.</span> 
            <v-img class="rotate-peixe mt-14 mb-8" src="@/assets/peixe.png" :height="!isBig ? '30px': '40px'" width="auto" contain></v-img>
          </div>
        </v-carousel-item>
      </v-carousel>
    </section>  
    <v-lazy>
      <section id="apoio" class="m-6">
        <h1 class="section-txt" style="text-align: center;font-size: 3rem;">Apoio</h1>    
        <div class="apoio-container">
          <div v-for="apoio in apoios" :key="apoio" class="apoio-wrapper"   :style="{ backgroundImage: 'url(' + require('@/assets/apoio/' + apoio + '.png') + ')' }"/>
        </div>
      </section>
    </v-lazy>
    <footer style="margin-top: 6rem;">
      <span style="display: block;text-align: center; font-size:0.8rem;color:#002A51">Design</span>
      <v-img style="cursor: pointer;" @click="routePragmatas()" contain width="auto" height="35px" src="@/assets/pragmatas.png"></v-img>
    </footer>
  </main>
</template>
<script>

export default {
  name:"TrueHome",
  data(){
    return{
      apoios: ['350', 'ahomar','amupescar','rede_ahomar','sindipesca','icmbio','lipesca','marinha','apeapa', 'ibama'],
      infoCards: ['pescadores', 'pescadoras', 'especies', 'unidades']
    }
  },
  computed: {
    isBig() {
      return this.$vuetify.breakpoint.mdAndUp ? true : false
    }
  },
  methods:{
    routePragmatas(){
      window.open('https://pragmatas.org')
    },
    getCardImg(card){
      return require(`@/assets/info-cards/${card}.svg`)
    },
    scrollToSection(sectionId) {      
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        history.pushState(null, null, `#${sectionId}`);
      }
    },    
    goToMap(){
      this.$router.push('/mapa')
    }
  },
  mounted() {
    if (this.$route.hash) {
      this.scrollToSection(this.$route.hash.replace('#', ''));
    }
  }
}
</script>

<style scoped>
.mega-container{
  padding: 0 1rem;
  @media(min-width:960px){
      padding: 0 20%;      
    }
}
.rotate-peixe{
  cursor: pointer;
}
.apoio-container {
  margin: 2rem;
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.apoio-wrapper {
  width: 150px;
  height: 150px; 
  background-size: contain;
  background-position: center;
}
#realizacao{
  padding-right: 0.7rem;
  padding-left: 0.4rem;
  @media(min-width:960px){
      padding: 0 20%;      
    }
    
}
.carousel-wrapper{
  background-color: #002A51;
  height: 100%;
  border-radius: 14px;
  padding: 1rem 4rem 2rem 4rem;
  p{
    color: white;   
    color: #fff;
    font-weight: 300;
    @media(min-width:960px){
      font-size: 2rem;
      padding: 22px 20px !important;
    }
  }
}

.carousel-text {
  color: white;   
  color: #fff;
  font-weight: 300;
  @media(min-width:960px){
    font-size: 2rem;
    padding: 22px 20px !important;
  }
}
.carousel-title{
  text-align:center;
  padding: 14px 0px;
  color: white;
  @media(min-width:960px){
      font-size: 3rem;
      padding: 22px 20px !important;
    }
}
.fh-section{
  height: 100dvh;
}
#sobre{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-home {
  background-color: #82BA32;
  padding: 1.3rem 2.3rem !important;
  font-size: 2vw;
  color: white;
  position: relative;
  bottom: 50px;
  border-radius: 15px;
  @media(max-width:960px) {
    font-size: 1.4rem;
    padding: 1.5rem 1.8rem !important;
    position: static;
  }
  @media(max-width:460px) {    
    padding: 26px 24px !important;
  }
}
.button-home:hover{
  background-color: #79aa33;
}
.section-txt{
  font-size: 2rem;  
  padding: 2rem;
  color: #002A51 !important;
  @media(max-width:960px){
    font-size: 1.2rem;
    padding: 22px 20px !important;
  }
}
.mapa-title{
  position: absolute;
  font-size: 2.2rem;
  top: 45vh;
  left: 51%;
  transform: translate(-50%, -50%);
  color: white;
  width: 90%;
  text-align: left;
  @media(max-width:960px){
    display: none;
  }
}
.mapa-home {
  height: 60vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-image: url(../assets/bg/home_bg.png);
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width:960px) {
    height: 33vh;
    border-radius: 0px 0px 20px 20px;
  }
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; 
  gap: 20px; 
}
.card-info{
  max-width: 350px;
  position: relative;
  background-image: url(../assets/icons/icons-home/pescadores.png);    
  background-size: contain;
  background-position: center;
  flex: 0 0 calc(50% - 20px); /* Sets the basis for each card to take up half the container width minus the gap */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
  width: 25vh;  
  background-color: #82BA32;
  border-radius: 15px;
  .unidades{
    bottom: 5px;
    font-size: 1rem;
    text-align: center;
  }
  div{
    position: absolute; /* Corrected from display: absolute; to position: absolute; */
    bottom: 45px;
    color: #002A51;
    font-weight: bold;
    font-size: 1.2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  div:nth-child(2){
    bottom: 20px;
    font-size: 1.2rem;
  }
}
.card-info:nth-child(2){
  background-image: url(../assets/icons/icons-home/pescadoras.png);
}
.card-info:nth-child(3){
  background-image: url(../assets/icons/icons-home/especies.png);
}
.card-info:nth-child(4){
  background-image: url(../assets/icons/icons-home/unidades.png);
}

</style>